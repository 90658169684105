import restaurant from "./images/tesalia.jpg";
import img_card from "./images/img1.jpg";
import imgheaderrestaurant from "./images/headerrestaurant.jpg";
import imgheader from "./images/header.webp";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/header.jpg";
import imgheadermiinimb from "./images/header.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "Dan",
        mireasa: "Alina",
        data: "19 Noiembrie 2022",
        data_confirmare: "10 noiembrie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "anusic007@gmail.com", 
       tel: "+373 673 90 675",
       phone: "tel:+37367390675",
       viber: "viber://chat?number=%2B37367390675",
       whatsapp: "https://wa.me/+37367390675",
       messenger: "https://www.messenger.com/t/InvitatiiWeb",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Tesalia",
            data: "19 noiembrie 2022, sâmbătă, ora 17:00",
            adress: "Șoseaua Hînceşti 294, Ialoveni 6801",
            harta: "https://goo.gl/maps/6QcDfHF2KFjDxSTc6",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10892.148700719732!2d28.7480613!3d46.9609596!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8a5a5f5d7adcc261!2sTesalia!5e0!3m2!1sro!2s!4v1648740787752!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;